@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h3,
h4,
h5,
h6,
h2,
h1 {
  margin: 0 !important;
  /* color: #ff45604a; */
  /* color: rgba(134, 141, 2, 0.29); */
  /* color: #ff45604a; */
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: unset !important;
}

.overviewSkeleton {
  width: 100% !important;
}

table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d7d7d780;
}

.Table_th__3Zo_Y {
  padding: 8px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #d22f31;
  background: #d22f321f;
  text-transform: capitalize;
  border-right: 1px solid #d7d7d780;
}

.Table_tbody__3-Um1 {
  font-size: 0.5rem;
}
.Table_recentReview__27nPh button {
  background-color: #d22f31;
  color: aliceblue;
  border: none;
  outline: none;
  font-size: 11px;
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 3px;
}

tr a {
  text-decoration: none;
  color: #fff;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 3px;
  background: #d22f31;
}

tbody tr td {
  padding: 7px 10px;
  font-size: 10.8px;
  text-transform: uppercase;
}

.SearchInput_search-box__3s4bF {
  display: flex;
  align-items: center;
  margin-left: 50%;
  background: #f8fdff;
  /* width: 27.5em; */
  height: 30px;
  padding: 0 10px;
  border-radius: 10px;
}
.SearchInput_search-box__3s4bF svg {
  color: grey;
}

.SearchInput_search-box__3s4bF input {
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  margin-left: 5px;
  font-size: 12px;
}

.SearchInput_search-box__3s4bF input::-webkit-input-placeholder {
  font-size: 10px;
}

.SearchInput_search-box__3s4bF input::placeholder {
  font-size: 10px;
}

