/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p,
h3,
h4,
h5,
h6,
h2,
h1 {
  margin: 0 !important;
  /* color: #ff45604a; */
  /* color: rgba(134, 141, 2, 0.29); */
  /* color: #ff45604a; */
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: unset !important;
}

.overviewSkeleton {
  width: 100% !important;
}
