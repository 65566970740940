table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d7d7d780;
}

.th {
  padding: 8px;
  font-size: 0.8rem;
  font-weight: 500;
  color: #d22f31;
  background: #d22f321f;
  text-transform: capitalize;
  border-right: 1px solid #d7d7d780;
}

.tbody {
  font-size: 0.5rem;
}
.recentReview button {
  background-color: #d22f31;
  color: aliceblue;
  border: none;
  outline: none;
  font-size: 11px;
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 3px;
}

tr a {
  text-decoration: none;
  color: #fff;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 3px;
  background: #d22f31;
}

tbody tr td {
  padding: 7px 10px;
  font-size: 10.8px;
  text-transform: uppercase;
}
